import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import FAQItem from "../components/Faq/Faq"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: center;
  padding: 1em;

  h2 {
    color: var(--primary);
    margin-bottom: 2em;
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }
`

const faq = () => {
  return (
    <>
      <Seo title="FAQ" />
      <Layout>
        <Container>
          <h2>Najczęściej Zadawane Pytania</h2>
          <FAQItem
            title="Jak długo pracujemy w branży eventowo - ślubnej?"
            description='Zespół Muzyczny "MEMENTO" istnieje w branży ślubnej od 2009 roku. Od powstania skład się lekko zmieniał i nabierał doświadczenia jednak od 5 lat zespół jest stały w składzie.'
          />

          <FAQItem
            title="Jaki zakres godzin obejmuje nasza usługa?"
            description="Wszystko jest zależne czy jest to wesele, event czy firmówka.  Do każdego klienta podchodzimy indywidualnie."
          />

          <FAQItem
            title="Jakie elementy zawiera nasza podstawowa oferta?"
            description={
              <ul>
                <li>Obsługa muzyczna</li>
                <li>Konferansjerska</li>
                <li>Prowadzenie zabaw</li>
                <li>Nagłośnienie</li>
                <li>Oświetlenie</li>
              </ul>
            }
          />

          <FAQItem
            title="Jaki repertuar proponujemy?"
            description="Nasz repertuar jest bardzo elastyczny, dlatego każdy znajdzie w nim coś dla siebie. Gramy szeroko pojętą muzykę rozrywkową - hity, które zna każdy należące do gatunków muzycznych począwszy od lat 60 aż po muzykę nowoczesną."
          />

          <FAQItem
            title="Na jakich instrumentach gramy?"
            description="Instrumenty klawiszowe, gitara elektryczna, gitara elektroakustyczna, skrzypce, saksofony, klarnet."
          />

          <FAQItem
            title="Na jakim sprzęcie pracujemy?"
            description="Electro Voice, Shure, Behringer, Yamaha, Proel, Sennheiser, LD System, Dowina, Fender"
          />

          <FAQItem
            title="Jak często obsługujemy imprezy międzynarodowe?"
            description="Dość często, najczęściej to wesela : angielsko-polskie, polsko-niemieckie i polsko-rosyjskie."
          />

          <FAQItem
            title="Czy oferujemy efekty świetlne w trakcie prowadzenia imprezy?"
            description='Owszem, posiadamy wszystkie urządzenia z oświetleniem ledowym : Głowy ruchome, pary, bary, mgielnica, ciężki dym.  Wszystkie urządzenia są profesjonalnie zaprogramowane i ustawiane "manualnie" (nie jak w większości wypadków - świeci jak chce) :)'
          />

          <p className="link-container">
            <Link to="/contact">Jeśli masz inne pytanie napisz do nas!</Link>
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default faq
